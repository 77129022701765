'use client'
import { usePathname, useSearchParams } from 'next/navigation'
import { useEffect } from 'react'

function appendScriptFromString(htmlString: string)
{
	// Create a temporary container div
	const tempDiv = document.createElement('div');
	// Set the innerHTML of the temporary div to the HTML string
	tempDiv.innerHTML = htmlString;

	// Extract the script element from the temporary div
	const scriptElement = tempDiv.querySelector('script');

	if (scriptElement)
	{
		// Create a new script element
		const newScriptElement = document.createElement('script');

		// Copy all attributes from the original script element to the new one
		Array.from(scriptElement.attributes).forEach(attr =>
		{
			newScriptElement.setAttribute(attr.name, attr.value);
		});

		// Set the src attribute
		newScriptElement.src = scriptElement.src;

		// Append the new script element to the body
		document.body.appendChild(newScriptElement);
	} else
	{
		console.error('No script tag found in the provided HTML string');
	}
}

export default function ApntAutoops({ scriptCode }: { scriptCode: string })
{
	const pathname = usePathname();
	const searchParams = useSearchParams();

	useEffect(() =>
	{
		appendScriptFromString(scriptCode);
	})

	useEffect(() =>
	{

		let cb = () =>
		{
			if (window && (window as any).AutoOps && (window as any).AutoOps.show)
			{
				(window as any).AutoOps.show();
			}
		}

		let links = document.querySelectorAll("a");
		for (let i = 0, len = links.length; i < len; i++)
		{
			let href = links[i].getAttribute("href");
			if (
				href == "#make-appointment" ||
				links[i].classList.contains("av-appointment-link")
			)
			{
				const el = links[i];
				if (el && el.removeEventListener)
				{
					el.removeEventListener("click", cb, false);
					el.addEventListener("click", cb, false);
				}
			}
		}
	}, [pathname, searchParams])
	return <></>;
}

