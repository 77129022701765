'use client'
import { Partytown } from '@builder.io/partytown/react';
import Script from "next/script";
import { useEffect } from 'react';

export function DsxShopTracking({ ga_code, gtm_code }: { ga_code: string | null, gtm_code: string | null })
{

	useEffect(() =>
	{

		const letsParty = true;

		if (letsParty)
		{
			const scriptPt = document.createElement('script');
			scriptPt.type = 'text/javascript';
			scriptPt.src = '/~partytown/partytown.js';
			scriptPt.async = false;
			document.head.appendChild(scriptPt);

			const scriptPtCode = document.createElement('script');
			scriptPtCode.type = 'text/javascript';
			scriptPtCode.innerHTML = `partytown = {debug: true,
			lib: '/~partytown/',
			forward: ['dataLayer.push','gtag']
		};`;
			scriptPtCode.async = false;
			document.head.appendChild(scriptPtCode);
		}

		if (ga_code)
		{
			const scriptGa = document.createElement('script');

			if (letsParty)
			{
				scriptGa.type = 'text/partytown';
			} else
			{
				scriptGa.type = 'text/javascript';
			}

			scriptGa.src = `https://www.googletagmanager.com/gtag/js?id=${ga_code}`;
			scriptGa.async = false;
			document.head.appendChild(scriptGa);

			const scriptGaCode = document.createElement('script');

			if (letsParty)
			{
				scriptGaCode.type = 'text/partytown';
			} else
			{
				scriptGaCode.type = 'text/javascript';
			}

			scriptGaCode.innerHTML = `window.dataLayer = window.dataLayer || [];
              function gtag(){ dataLayer.push(arguments); }
              gtag('js', new Date());
              gtag('config', '${ga_code}');`;
			scriptGaCode.async = false;
			document.head.appendChild(scriptGaCode);
		}

		if (gtm_code)
		{

			const scriptGtmCode = document.createElement('script');

			if (letsParty)
			{
				scriptGtmCode.type = 'text/partytown';
			} else
			{
				scriptGtmCode.type = 'text/javascript';
			}

			scriptGtmCode.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gtm_code}');`;
			scriptGtmCode.async = false;
			document.head.appendChild(scriptGtmCode);

		}

	}, [ga_code, gtm_code]);

	return <div></div>;




}
